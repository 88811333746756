/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages


          // Back-to-top
          $('body').prepend('<a href="#" class="back-to-top">Back to Top</a>');

          var amountScrolled = 300;

          $(window).scroll(function() {
            if ($(window).scrollTop() > amountScrolled) {
              $('a.back-to-top').fadeIn('slow');
            } else {
              $('a.back-to-top').fadeOut('slow');
            }
          });



          $('a.back-to-top, a.simple-back-to-top').on('click',function() {
            var body = $('body,html');
            body.stop().animate({ scrollTop: 0 }, 500 , function() {
              console.log('Finished animating ' + body.height());
            });
            return false;
          });
        

        /**
         * OWL Carousel
         */
        var owl_settings_equipo = {
          loop:true,
          nav:true,
          dots: false,
          stagePadding: 60,
          margin:65,
          lazyLoad:true,
          responsiveClass:true,
          navText:['&#x3c;&#x3c;','&#x3e;&#x3e;'],
          responsive:{
            0:{
              items:1,
              nav:true
            },
            600:{
              items:3,
              nav:true
            },
            1000:{
              items:5,
              nav:true,

            }
          }
        };
        $('#owl-carousel-equipo').owlCarousel(owl_settings_equipo);



        var owl_settings_proyectos = {
          loop:true,
          nav:true,
          dots: false,
          stagePadding: 0,
          margin:0,
          responsiveClass:true,
          navText:['&#x3c;','&#x3e;'],
          autoplay:true,
          autoplayTimeout:5000,
          autoplayHoverPause:true,
          responsive:{
            0:{
              items:1,
              nav:false
            },
            540:{
              items:1,
              nav:true
            },
            1000:{
              items:1,
              nav:true
            }
          }
        };
        $('#owl-carousel-proyectos').owlCarousel(owl_settings_proyectos);





      // hay elementos con textos centrados en vertical. La altura del elemento en funcion del tamaño de la ventana
      var section_covered =  $('.section_covered');
      var vcentered = $('.vcenter');
      var carouselHome = $('#owl-carousel-proyectos');

      var altura_seccion_original = section_covered.height();
      var altura_elemento_centrable = vcentered.height();
      var altura_dispositivo = $(window).height();

      //var altura_menu = $('#navbar-rightbar').height();
      //var altura_visible = altura_dispositivo - altura_menu;
      var altura_visible = altura_dispositivo;

      var altura = altura_seccion_original;

      if(altura_seccion_original < altura_visible){
        //altura =  altura_dispositivo + 'px';
        altura =  altura_dispositivo + 'px';

      }else{
        altura = 'auto';
      }

      // if(altura_seccion_original > altura_dispositivo){
      //   altura = altura_dispositivo + 'px';
      // }else{
      //   altura = altura_seccion_original + 'px';
      // }


      vcentered.css('height', altura);
      section_covered.css('height', altura);
      carouselHome.css('height', altura);

      var modal =  $('.modal-dialog');
      modal.css('height', altura);
      modal.css('background-color', 'white');
      modal.css('max-width', 'initial');

      $(window).resize( function () {

        altura_seccion_original = section_covered.height();
        altura_elemento_centrable = vcentered.height();
        altura_dispositivo = $(window).height();

        //var altura_menu = $('#top_menu_header').height();
        //var altura_visible = altura_dispositivo - altura_menu;
        var altura_visible = altura_dispositivo;

        //altura = altura_seccion_original;
        altura = altura_seccion_original;

        if(altura_seccion_original < altura_visible){
          altura =  altura_dispositivo + 'px';
        }else{
          altura = 'auto';
        }


        // if(altura_seccion_original > altura_dispositivo){
        //   altura = altura_dispositivo + 'px';
        // }else{
        //   altura = altura_seccion_original + 'px';
        // }


        // console.log(altura_seccion_original);
        // console.log(altura_elemento_centrable);
        // console.log(altura_dispositivo);
        // console.log(altura);

        vcentered.css('height', altura);
        section_covered.css('height', altura);
        carouselHome.css('height', altura);
      });


          //manejamos el click de los enlaces con hash hacia anchors para animar el salto al anchor
          //$('a[href*="#"]').bind('click',function (e) {
          $('a.next_section_link').bind('click',function (e) {
              e.preventDefault();
              var altura_menu = $('#navbar-rightbar').height();
              var target = this.hash;
              $target = $(target);
              var tope = $target.offset().top;

              $('html, body').stop().animate({
                  'scrollTop': tope
              }, 1000, 'swing', function () {
                  //window.location.hash = target;
                if(history.pushState) {
                  history.pushState(null, null, target);
                }
                else {
                  location.hash = target;
                }
              });
          });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
